import { useState, useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import { Button, Error } from "../../Components/UI";
import useAuthApp from "../../hooks/useAuthApp";
import { authAction } from "../../store/auth/auth-slice";
import { onSuccess, onFailedMsg } from "../../helper/showHideAlert";
import { onRememberMeHandlerMd } from "../../store/presist/presist-action";

const VerifyOTP = ({ userData }) => {
  const { authFetch, navigate, dispatch } = useAuthApp();
  const [OTP, setOTP] = useState("");
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [errors, setErrors] = useState();
  const [timer, setTimer] = useState(30);
  const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
      } else {
        setCanResend(true);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  const onOTPVerified = async (e) => {
    e.preventDefault();
    if (OTP.length < 6) {
      setErrors("Please enter your OTP code");
      return false;
    }
    setLoading(true);

    try {
      const requestedBody = {
        mobileNumber: userData?.mobileNumber,
        password: OTP,
      };
      const { data } = await authFetch.post("/user/login", requestedBody);
      if (data.status === true) {
        dispatch(
          authAction.onUpdateUserToken(data?.data?.accesstoken || undefined)
        );
        await localStorage.setItem("token", data?.data?.accesstoken);
        await localStorage.setItem("update-password", true);
        onSuccess(dispatch, data?.message);
        navigate("/dashboard");
        dispatch(onRememberMeHandlerMd(false));
      } else {
        onFailedMsg(dispatch, data?.message);
      }
    } catch (e) {
      onFailedMsg(dispatch, e?.response?.data?.error);
    } finally {
      setOTP("");
      setLoading(false);
    }
  };

  const setOTPHandler = (e) => {
    const value = e.target.value;
    if (value.length > 6) return;
    setOTP(value);
    if (!value) {
      setErrors("Please enter your OTP code");
    } else {
      setErrors("");
    }
  };

  const onResetOTPHandler = async () => {
    try {
      if (resendLoading) return false;
      setResendLoading(true);
      const requestedBody = {
        mobileNumber: userData?.mobileNumber,
      };
      const { data } = await authFetch.post(
        `/user/forgot-password`,
        requestedBody
      );
      if (data?.status === true) {
        onSuccess(dispatch, data?.message);
        setTimer(30);
        setCanResend(false);
      } else {
        onFailedMsg(dispatch, data?.message);
      }
    } catch (e) {
      onFailedMsg(dispatch, e?.response?.data?.error);
    } finally {
      setResendLoading(false);
    }
  };

  return (
    <section>
      <div>
        <div>
          <div>
            <div>
              <form
                className="form-floating"
                autoComplete="off"
                onSubmit={onOTPVerified}
              >
                <div className="custom-control form-floating ">

                  <input
                    type="number"
                    autoFocus={true}
                    className="form-control"
                    name="otp"
                    title="OTP Verification Code"
                    placeholder="Code"
                    value={OTP}
                    onChange={setOTPHandler}
                    maxLength={6}
                    id="otp"
                    required
                  />

                  <label htmlFor="otp" className="floatingInput px-1">
                    Verification Password
                  </label>
                  {canResend ? (
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={onResetOTPHandler}
                      className="eye-password"
                      disabled={resendLoading}
                    >
                      {resendLoading ? "Loading" : "Get Code"}
                    </span>
                  ) : (
                    <span className="eye-password">
                      Resend in {timer}
                    </span>
                  )}


                  {errors && <Error>{errors}</Error>}
                </div>



                <Button
                  type="submit"
                  isDisabled={loading}
                  className="btn btn-primary butt-nox btn-block text-uppercase my-2  shadow-sm"
                >
                  {loading ? "Loading..." : "Verify & Login"}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerifyOTP;
