import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link } from "react-router-dom";
import { BgImage, Error, ShowHidePwd } from "../../Components/UI/index";
import useAuthApp from "../../hooks/useAuthApp";
import { onFailedMsg, onSuccess } from "../../helper/showHideAlert";
import { authAction } from "../../store/auth/auth-slice";

const Register = () => {
  const { authFetch, navigate, dispatch } = useAuthApp();
  const { token } = useSelector((state) => state.auth);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [values, setValues] = useState({
    userName: "",
    mobileNumber: "",
    password: "",
  });
  // let check = /^[A-Za-z\s]+$/.test(values.userName);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (token) {
      navigate("/dashboard ");
    } else {
      localStorage.clear();
    }
  }, [navigate, token]);

  const handleChangeMobile = (value, data) => {
    delete data?.format;

    setValues((previousState) => {
      return {
        ...previousState,
        mobileNumber: value.slice(data.dialCode.length),
      };
    });
    setErrors((previousState) => {
      return {
        ...previousState,
        mobile_number:
          value.slice(data.dialCode.length) === ""
            ? "Mobile Number is required"
            : "",
      };
    });
  };

  const onChnageValue = (e) => {
    if (e.target.name === "userName") {
      let check = /^[A-Za-z\s]+$/.test(e.target.value);

      if (check || e.target.value.trim() === "") {
        setValues((previousState) => {
          return {
            ...previousState,
            [e.target.name]: e.target.value,
          };
        });

        setErrors((previousState) => {
          return {
            ...previousState,
            [e.target.name]:
              e.target.value.trim() === ""
                ? `${e.target.title} is required`
                : "",
          };
        });
      } else {
        setErrors((previousState) => {
          return {
            ...previousState,
            [e.target.name]: `${e.target.title} should contain only alphabets`,
          };
        });
      }
    } else {
      setValues((previousState) => {
        return {
          ...previousState,
          [e.target.name]: e.target.value,
        };
      });

      setErrors((previousState) => {
        return {
          ...previousState,
          [e.target.name]:
            e.target.value.trim() === "" ? `${e.target.title} is required` : "",
        };
      });
    }
  };

  const isFormValidate = () => {
    // let check = /^[A-Za-z\s]+$/.test(values.userName);
    if (
      values.userName?.trim() === "" ||
      values.mobileNumber === "" ||
      Number(values?.mobileNumber?.length) !== Number(10) ||
      values.password?.trim() === ""
    ) {
      setErrors({
        ...errors,
        userName: values.userName?.trim() === "" ? "User Name is required" : "",
        mobile_number:
          values.mobileNumber === ""
            ? "Mobile Number is required"
            : Number(values?.mobileNumber?.length) !== Number(10)
              ? "Invalid Mobile Number"
              : "",
        password: values.password?.trim() === "" ? "Password is required" : "",
      });
      return false;
    }

    if (values.password.length < 6) {
      setErrors({
        ...errors,
        password:
          values.password.length < 8 ? "Password Must Be 6 Character Long" : "",
      });
    }

    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (isFormValidate() && !btnLoading) {
      setBtnLoading(true);
      try {
        const trimmedValues = { ...values, userName: values.userName.trim() };
        const { data } = await authFetch.post("/user/register", trimmedValues);
        if (data && data?.status === true) {
          const body = {
            mobileNumber: values.mobileNumber,
            password: values.password,
          };
          const { data } = await authFetch.post("/user/login", body);

          if (data && data?.status === true) {
            localStorage.setItem("token", data?.data?.accesstoken);
            dispatch(
              authAction.onUpdateUserToken(data?.data?.accesstoken || undefined)
            );

            navigate("/dashboard");
          } else {
            onFailedMsg(dispatch, data.error);
          }
          onSuccess(dispatch, "User Register Successfully");
          // navigate("/login");
        } else {
          onFailedMsg(dispatch, data?.error);
        }
      } catch (e) {
        onFailedMsg(dispatch, e?.response?.data?.error);
      } finally {
        setBtnLoading(false);
      }
    }
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <main className="login_main_box">
      <div className="container-fluid">
        <div className="row no-gutter">
          <div className="col-lg-6 col-sm-12 col-md-12 col-12  bg-imagae d-none d-lg-flex">
            <div className="logo-box ">
              <BgImage />
            </div>
          </div>

          <div className="col-lg-6 col-sm-12 col-md-12 col-12 bg-light">
            <div className="login d-flex align-items-center py-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 col-sm-12 col-md-11 col-11  mx-auto">
                    <img
                      className=" mobile_logo"
                      src="/assets/images/logo1.png"
                      alt="logo-img"
                      width="256"
                    />
                    <h5 className="logi-font-bod">Register</h5>
                    <h2 className="logi-font-box">welcome</h2>
                    <p className="logi-font-box-a mb-4">
                      {" "}
                      {/* Please Create Your Account. */}
                    </p>
                    <form className="" autoComplete="off" onSubmit={onSubmit}>
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="Username"
                          autoFocus={true}
                          name="userName"
                          title="User Name"
                          value={values.userName}
                          onChange={onChnageValue}
                          maxLength={25}
                          disabled={btnLoading}
                        />
                        {errors?.userName && <Error>{errors?.userName}</Error>}
                        <label htmlFor="floatingInput">Enter Your Name</label>
                      </div>
                      <div className="form-floating mb-3">
                        <PhoneInput
                          inputProps={{
                            name: "phone",
                            required: true,
                            id: "mobileNum",
                          }}
                          disableDropdown={true}
                          countryCodeEditable={false}
                          country={"in"}
                          preferredCountries={["in"]}
                          value={`+91 ${values?.mobileNumber}`}
                          onChange={handleChangeMobile}
                          disabled={btnLoading}
                        />
                        {errors?.mobile_number && (
                          <Error>{errors?.mobile_number}</Error>
                        )}
                        {/* <label htmlFor="floatingInput">Mobile Number</label> */}
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          className="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          type={showPassword ? "text" : "password"}
                          name="password"
                          title="Password"
                          value={values.password}
                          onChange={onChnageValue}
                          maxLength={25}
                          disabled={btnLoading}
                        />
                        <span className=" eye-password">
                          <ShowHidePwd
                            isShowPwd={!showPassword}
                            onTogglePassword={togglePassword}
                          />
                        </span>
                        {errors?.password && <Error>{errors?.password}</Error>}
                        <label htmlFor="floatingPassword">Set Password</label>
                        <i className="bx bx-low-vision eye-password"></i>
                      </div>

                      <div className="text-center">
                        <button
                          style={{ color: "#fff" }}
                          type="submit"
                          disabled={btnLoading}
                          className="btn btn-primary butt-nox btn-block text-uppercase mb-2  shadow-sm"
                        >
                          {" "}
                          {btnLoading ? "Loading..." : "Register"}
                        </button>
                        <div className="account__switch">
                          <p>
                            Already have an account?{" "}
                            <Link className="line_font" to="/login">
                              Login
                            </Link>
                          </p>

                          <Link to="/landing" className="mt-3">
                            <i className="fa-solid fa-arrow-left-long"></i>{" "}
                            <b>
                              {" "}
                              Back to&nbsp;
                              <span>Home</span>
                            </b>
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Register;
